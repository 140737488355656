import { useEffect, useState } from 'react';
import { fetchHeartcoreData } from '../services/fetch-data';
import { IPage } from '../types/heartcore.types';
import { getMetaData } from '../utils/utils';
import { getContextWithChildren, getRoutePageContext, getProtectedStandardPagePageContext, getNewsLibraryPageContext, getFAQPageContext } from '../services/page-context-getters';

export function useContent<T extends IPage>(pageContext: T, rootNodeUrl = '') {
	const [ content, setContent ] = useState<T>({} as T);
	const [ meta, setMeta ] = useState(getMetaData(pageContext));

	const fetchPreviewData = async () => {
		let context;
		const data: IPage = await fetchHeartcoreData(`https://cdn.umbraco.io/content/${pageContext.id}`, 
			{ 
				method: 'GET',
				headers: {
					'Api-Version': '2',
					'Accept-Language': 'en-US',
				},
			});

		if(data.contentTypeAlias === 'routePage') {
			context = await getRoutePageContext(null, data, rootNodeUrl);
		} else if(
			data.contentTypeAlias === 'routesPage' || 
			data.contentTypeAlias === 'operationalChangesOverviewPage' ||
			data.contentTypeAlias === 'articlesLibraryPage'
		) {
			context = await getContextWithChildren(data, rootNodeUrl);
		} else if(data.contentTypeAlias === 'protectedStandardPage') {
			context = await getProtectedStandardPagePageContext(data);
		} else if(data.contentTypeAlias === 'newsLibraryPage') {
			context = await getNewsLibraryPageContext(data, rootNodeUrl);
		} else if(data.contentTypeAlias === 'fAQPage') {
			context = await getFAQPageContext(data);
		}

		setContent({
			id: pageContext.id,
			url: pageContext.url, 
			parent: pageContext.parent,
			...(context ?? data),
		});
		setMeta(getMetaData(data));
	};

	useEffect(() => {
		const isPreviewMode = process.env.GATSBY_UMBRACO_HEARTCORE_PREVIEW_MODE && JSON.parse(process.env.GATSBY_UMBRACO_HEARTCORE_PREVIEW_MODE);
		
		if(isPreviewMode) {
			fetchPreviewData();
		} else {
			setContent(pageContext);
		}

	}, []);

	return { ...content, meta: meta};
}