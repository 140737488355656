/* eslint-disable @typescript-eslint/no-var-requires */
const { fetchHeartcoreData } = require('./fetch-data');
const { getCleanUrl, getContent } = require('../utils/shared-build-runtime-utils');


const getChildren = (parentId, descendants, rootNodeUrl) => {
	const rawChildren = descendants.filter(page => page.parentId === parentId);
	return rawChildren.map(rc => ({ ...rc, id:rc._id, url:getCleanUrl(rc._url, rootNodeUrl) }));
};

const fetchDescendantsContent = async(contextId) => {
	const descendantsContent = await fetchHeartcoreData(`https://cdn.umbraco.io/content/${contextId}/descendants?hyperlinks=false&pageSize=100000`, {
		method: 'GET',
		headers: {
			'Api-Version': 2,
			'Umb-Project-Alias': process.env.GATSBY_UMBRACO_HEARTCORE_PROJECT_ALIAS,
			'Api-Key': process.env.GATSBY_UMBRACO_HEARTCORE_API_KEY,
			'Accept-Language': 'en-US',
		},
	});
	return descendantsContent;
};

const getProtectedStandardPagePageContext = async (context) => {
	return { ...context, modules: null };
};

const getContextWithChildren = async (context, rootNodeUrl, descendantsContent = null) => {
	if(!descendantsContent) {
		descendantsContent = await fetchDescendantsContent(context._id);
	}
	const descendants = getContent(descendantsContent);
	return { ...context, children: getChildren(context._id, descendants, rootNodeUrl) };
};

const isIndexableField = (key) => {
	let isIndexable = false;
	switch(key) {
		case 'description':
		case 'headline':
		case 'caption':
		case 'quote':
		case 'author':
		case 'authorCaption':
		case 'bodyText':
			isIndexable = true;
			break;
	}
	return isIndexable;
};

const getNewsLibraryPageContext = async (context, rootNodeUrl, descendantsContent = null) => {
	context = await getContextWithChildren(context, rootNodeUrl, descendantsContent);
	const searchData = context.children.map(child => {
		return {
			id: child._id,
			tags: child.tags,
			headline: child.headline,
			introText: child.introText,
			body: child.modules.map(module => {
				const searchableData = [];
				for(const key in module) {
					if(isIndexableField(key)){
						searchableData.push(module[key]);
					}
				}
				return searchableData;
			}),
		};
	});
	context.searchData = {
		searchContent: searchData,
		options: {
			indexStrategy: 'Prefix match',
			searchSanitizer: 'Lower Case',
			HeadlineIndex: true,
			IntroTextIndex: true,
			BodyIndex: true,
			SearchByTerm: true,
		},
	};
	return context;
};

const getFAQPageContext = async (context) => {
	const searchData = [];
	context.questions.forEach((question, index) => {
		question.entries.forEach((entry, entryIndex) => {
			searchData.push({
				id: `${question.headline}-${index}-${entryIndex}`,
				tags: [{tag: question.headline}],
				headline: entry.headline,
				body: entry.bodyText,
			});
		});
	});
	context.searchData = {
		searchContent: searchData,
		options: {
			indexStrategy: 'Prefix match',
			searchSanitizer: 'Lower Case',
			HeadlineIndex: true,
			IntroTextIndex: false,
			BodyIndex: true,
			SearchByTerm: true,
		},
	};
	return context;
};

const getRoutePageContext = async (parentNode, context, rootNodeUrl, selectedOperationalChangePageDescendantsContent = null ) => {
	if(!parentNode) {
		parentNode = 	await fetchHeartcoreData(`https://cdn.umbraco.io/content/${context.parentId}`, 
			{ 
				method: 'GET',
				headers: {
					'Api-Version': '2',
					'Accept-Language': 'en-US',
				},
			});
	}
	const selectedOperationalChangePageId = parentNode.operationalChangesOverviewPagePicker && parentNode.operationalChangesOverviewPagePicker._id;
	if(!selectedOperationalChangePageDescendantsContent) {
		selectedOperationalChangePageDescendantsContent = await fetchDescendantsContent(selectedOperationalChangePageId);
	}
	const selectedOperationalChangePageDescendants = getContent(selectedOperationalChangePageDescendantsContent);
	const selectedOperationalChangePageChildren = selectedOperationalChangePageId ? getChildren(selectedOperationalChangePageId, selectedOperationalChangePageDescendants, rootNodeUrl) : [];
	if(selectedOperationalChangePageChildren) {
		context.operationalChanges = []; 
		selectedOperationalChangePageChildren.forEach(oc => {
			if(oc.affectedRoutes && context.route) {
				oc.affectedRoutes.forEach(r => {
					if(r._id === context.route._id) {
						context.operationalChanges.push(oc);
					}
				});
			}
		});
	}

	return context;
};

module.exports = {
	getRoutePageContext: getRoutePageContext,
	getProtectedStandardPagePageContext: getProtectedStandardPagePageContext,
	getContextWithChildren: getContextWithChildren,
	getNewsLibraryPageContext: getNewsLibraryPageContext,
	getFAQPageContext: getFAQPageContext,
};
